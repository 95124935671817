<template>
  <main>
    <div class="page-content">
      <div class="row pb-5 text-center">
        <div class="col">
          <h5>Sign In for Investors</h5>
          <a
            class="btn btn-lg btn-primary"
            :href="investURL"
            style="margin-bottom: 5px"
            >Invest</a
          >
        </div>
        <div class="col">
          <h5>Sign In for Issuers</h5>
          <a
            class="btn btn-lg btn-primary"
            :href="raiseURL"
            style="margin-bottom: 5px"
            >Raise</a
          >
        </div>
      </div>
      <div>{{ errorNews }}</div>
      <div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 g-5">
          <div v-for="post in news" :key="post.id" class="col">
            <div class="card shadow-sm  post-single">
              <img
                v-if="post.imageURLMedium"
                :src="post.imageURLMedium"
                class="bd-placeholder-img card-img-top"
                height="200"
                width="100%"
              />
              <div class="card-body">
                <h3 class="card-title">{{ post.headline }}</h3>
                <p v-html="post.story" class="card-text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer v-if="news" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import getCollection from "@/composables/getCollection";
import { ref } from "@vue/reactivity";

export default {
  components: { Footer },
  setup() {
    const investURL = ref(process.env.VUE_APP_INVEST_URL);
    const raiseURL = ref(process.env.VUE_APP_RAISE_URL);

    const { error: errorNews, documents: news } = getCollection(
      "rhNews",
      [
        ["order", ">", 0],
        ["showOnAboutPage", "==", true]
      ],
      ["order", "asc"]
    );

    return {
      investURL,
      raiseURL,
      errorNews,
      news
    };
  }
};
</script>

<style scoped>
.story-text {
  /* white-space: pre-wrap; */
  text-align: left;
}
</style>

